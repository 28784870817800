<template>
  <div class="recipe-list-container">
    <v-container>
      <Breadcrumb v-if="$vuetify.breakpoint.smAndUp" :items="breadcrumb" />
      <h1>{{ $t("recipe.title") }}</h1>
      <div>{{ $t("recipe.subtitle") }}</div>

      <v-row>
        <v-col cols="12" md="9">
          <div v-if="content != null" @click="clicked">
            <v-card
              v-for="recipe in content"
              :key="recipe.id"
              class="recipe-card mb-5"
              data-test="recipe-card"
              flat
            >
              <v-img
                class="recipe-image"
                :src="recipe.featured_image_url"
                alt="Immagine ricetta"
              ></v-img>
              <div class="recipe-info">
                <v-card-title v-html="recipe.title.rendered"></v-card-title>
                <!-- <v-card-subtitle v-html="recipe.content.unrendered"></v-card-subtitle> -->
                <v-card-text>
                  <span
                    class="description"
                    v-html="recipe.content.unrendered"
                  ></span
                  ><br />
                </v-card-text>
                <div class="characteristics">
                  <span class="cook-time mr-4" v-if="recipe.recipe_cook_time">
                    <!-- <v-icon small class="mr-2">$clock</v-icon> -->
                    {{ recipe.recipe_cook_time }}</span
                  >
                  <span class="difficulty mr-4" v-if="rating">
                    <!-- <v-icon small class="mr-2">$chef</v-icon> -->
                    {{ rating }}</span
                  >
                  <span class="meal-course px-2" v-if="recipe.piatti"
                    ><strong>{{ recipe.piatti }}</strong></span
                  >
                </div>
                <v-card-actions class="justify-end mt-2 mr-3">
                  <v-btn
                    small
                    class="go-to-recipe align-center"
                    data-test="go-to-recipe"
                    color="primary"
                    depressed
                    :to="{
                      name: 'RecipeDetail',
                      params: { recipeName: recipe.slug }
                    }"
                  >
                    {{ $t("recipe.goToRecipeBtn") }}</v-btn
                  >
                </v-card-actions>
              </div>
            </v-card>
          </div>
          <div v-if="noRecipeToShow">
            Nessuna ricetta da mostrare
          </div>
        </v-col>
        <v-col cols="0" md="3" v-if="$vuetify.breakpoint.mdAndUp">
          <!-- <RecipeSearch courses="coursesData" ingredients="ingredientsData" /> -->
          <RecipeSearch changePage="false" />
          <SuggestedRecipeNews showNews="false" showRecipe="true" />
        </v-col>
      </v-row>
      <v-pagination
        color="white"
        v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
        :value="pageFilter"
        :page="pager.selPage"
        :length="pager.totPages ? pager.totPages : 0"
        :totalVisible="6"
        @next.stop.prevent="handlePageFilter"
        @prev="handlePageFilter"
        @input="handlePageFilter"
      ></v-pagination>
    </v-container>
  </div>
</template>
<style lang="scss">
.recipe-list-container {
  background-color: #351313;
  h1,
  .suggestedRecipe h3,
  .v-breadcrumbs * {
    color: #ffffff !important;
  }
  .recipe-card.v-card {
    display: flex;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      flex-direction: column;
      .v-card__title {
        height: auto !important;
      }
    }
    .recipe-image {
      border-radius: 4px 0 0 4px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        flex-direction: column;
        border-radius: 4px 4px 0 0;
      }
      @media #{map-get($display-breakpoints, 'sm-only')} {
        border-radius: 4px 4px 0 0;
        width: auto;
        max-width: auto;
        height: 240px;
      }
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        max-width: 40%;
        width: 40%;
      }
    }
    .v-card__title {
      font-weight: bold;
      font-size: 20px;
      color: var(--v-secondary-base);
      word-break: normal;
      max-height: 56px;
      height: 56px;
      overflow: hidden;
      padding: 0px;
      margin: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      /* autoprefixer: off */
    }
    .v-card__text {
      align-items: flex-start;
      font-size: 14px;
      word-break: break-word;
      max-height: 78px;
      height: 78px;
      overflow: hidden;
      width: 91%;
      padding: 0px;
      margin: 0px 16px 16px 16px;
      .description {
        overflow: hidden;
        height: 91px;
        max-height: 91px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        /* autoprefixer: off */
      }
    }
    .characteristics {
      font-size: 12px;
      line-height: 2;
      margin: 0px 16px;
      .meal-course {
        color: white;
        background-color: var(--v-secondary-base);
        border-radius: 10px;
      }
    }
  }
}
</style>
<script>
import RecipeSearch from "@/components/wordpress/RecipeSearch.vue";
import SuggestedRecipeNews from "@/components/wordpress/SuggestedRecipeNews.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMixins from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";

import CMService from "~/service/cmService";

import { mapGetters } from "vuex";

import get from "lodash/get";

export default {
  name: "RecipeList",
  mixins: [categoryMixins, clickHandler],
  components: { RecipeSearch, SuggestedRecipeNews, Breadcrumb },
  props: {
    s: {
      type: String,
      default: null
    },
    course: {
      type: String,
      default: null
    },
    ingredients: {
      type: String,
      default: null
    },
    cuisine: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      content: null,
      newFilters: false,
      noRecipeToShow: false,
      pager: {}
    };
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    }),
    rating() {
      return get(this.pageData, "rating");
    }
  },
  methods: {
    async fetchPage(pageNum) {
      let params = [];
      this.page = pageNum || this.pageFilter;
      console.log("pageParams", this.page);
      this.pager.selPage = this.page;
      params.push({
        filterName: "page",
        filterValue: this.page
      });
      if (this.s != null && this.s.length > 0) {
        params.push({
          filterName: "filter[s]",
          filterValue: this.s
        });
      }
      if (this.course != null && this.course.length > 0) {
        params.push({
          filterName: "filter[course]",
          filterValue: this.course
        });
      }
      if (this.ingredients != null && this.ingredients.length > 0) {
        params.push({
          filterName: "filter[ingredient]",
          filterValue: this.ingredients
        });
      }
      if (this.cuisine != null && this.cuisine.length > 0) {
        params.push({
          filterName: "filter[cuisine]",
          filterValue: this.cuisine
        });
      }
      const res = await CMService.getCustomPostByFilters("recipe", params);

      if (res && res.data && res.data.length) {
        this.pager = res.page;
        this.content = res.data;
        this.noRecipeToShow = false;
      } else {
        this.content = null;
        this.noRecipeToShow = true;
      }
      console.log(res);
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      this.fetchPage(page);
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  mounted() {
    this.fetchPage();
  },
  watch: {
    s() {
      let vm = this;
      if (this.newFilters == false) {
        this.newFilters = true;
        setTimeout(function() {
          vm.newFilters = false;
          vm.fetchPage(1);
        }, 300);
      }
    },
    cuisine() {
      let vm = this;
      // if (this.cuisine != null && this.newFilters == false) {
      if (this.newFilters == false) {
        this.newFilters = true;
        setTimeout(function() {
          vm.newFilters = false;
          vm.fetchPage(1);
        }, 300);
      }
    },
    ingredients() {
      let vm = this;
      if (this.newFilters == false) {
        this.newFilters = true;
        setTimeout(function() {
          vm.newFilters = false;
          vm.fetchPage(1);
        }, 300);
      }
    },
    course() {
      let vm = this;
      // if (this.course != null && this.newFilters == false) {
      if (this.newFilters == false) {
        this.newFilters = true;
        setTimeout(function() {
          vm.newFilters = false;
          vm.fetchPage(1);
        }, 300);
      }
    }
  }
};
</script>
